<template>
  <div class="layout_container">
    <router-view />
    <van-tabbar
      class="layout-tabbar"
      route
    >
      <van-tabbar-item
        icon="home-o"
        to="/"
      >首页</van-tabbar-item>
      <van-tabbar-item
        icon="orders-o"
        to="/order"
      >缴费记录</van-tabbar-item>
      <van-tabbar-item
        icon="user-o"
        to="/me"
      >个人中心</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Layout",
  data() {
    return {};
  },
  created() {},
  methods: {}
};
</script>
<style scope>
.layout_container .van-tabbar--fixed {
  z-index: 9999;
}
.layout_container .van-tabbar-item {
  font-size: 26px;
}
.layout_container .van-tabbar-item__icon {
  font-size: 50px;
}
</style>